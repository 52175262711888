var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_c('v-container', [_c('table', [_c('tbody', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.board.subject))])]), _c('tr', [_c('th', [_vm._v(" " + _vm._s(_vm.board.writer.name) + " · " + _vm._s(_vm.$dayjs(_vm.board.createdAt).format("YYYY-MM-DD HH:mm:ss")) + " · " + _vm._s(_vm.board.viewCount) + " ")])]), _vm._l(_vm.board.files, function (file, idx) {
    return _c('tr', {
      key: idx
    }, [_c('a', {
      attrs: {
        "href": file.url,
        "download": ""
      }
    }, [_c('th', [_vm._v("파일 첨부: " + _vm._s(file.fileName))])])]);
  }), _c('tr', [_c('th', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  })])])], 2)])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }