var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section"
  }, [_c('v-container', [_c('table', {
    staticClass: "board-view"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.board.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.board.writer.name) + " · " + _vm._s(_vm.$dayjs(_vm.board.createdAt).format("YYYY-MM-DD HH:mm:ss")) + " · " + _vm._s(_vm.board.viewCount) + " ")])]), _vm._l(_vm.board.files, function (file, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', {
      attrs: {
        "colspan": "2"
      }
    }, [_c('a', {
      staticClass: "d-block",
      attrs: {
        "href": file.url,
        "download": ""
      }
    }, [_vm._v("파일 첨부: " + _vm._s(file.fileName))])])]);
  }), _c('tr', [_c('td', {
    staticClass: "content_box",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  })])])], 2)]), _c('div', {
    staticClass: "d-flex justify-end mt-40 mt-lg-60"
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "font-size-14 font-size-lg-18"
  }, [_vm._v("LIST")])])], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }