<template>
    <client-page>
        <div id="contents">
            <v-container>
                <table>
                    <tbody>
                        <tr>
                            <th>{{ board.subject }}</th>
                        </tr>
                        <tr>
                            <th>
                                {{ board.writer.name }} · {{ $dayjs(board.createdAt).format("YYYY-MM-DD HH:mm:ss") }} · {{ board.viewCount }}
                            </th>
                        </tr>
                        <tr v-for="file, idx in board.files" :key="idx">
                            <a :href="file.url" download>
                                <th>파일 첨부: {{ file.fileName }}</th>
                            </a>
                        </tr>
                        <tr>
                            <th>
                                <div v-html="board.content"></div>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </v-container>
        </div>
    </client-page>
</template>

<script>
import api from "@/api"
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";

export default {
    components: {
        ClientPage,
    },
    async mounted() {
        await this.init()    
    },
    data() {
        return {
            board: undefined
        }
    },
    methods: {
        async init() {
            let { board } = await api.v1.boards.get({
                _id: this.$route.params.id 
            })
            console.log(board)
            this.board = board
        },
    },
};
</script>