<template>
    <client-page>
        <section class="sub-section">
            <v-container>
                <table class="board-view">
                    <tbody>
                        <tr>
                            <th colspan="2">{{ board.subject }}</th>
                        </tr>
                        <tr>
                            <td colspan="2">
                                {{ board.writer.name }} · {{ $dayjs(board.createdAt).format("YYYY-MM-DD HH:mm:ss") }} · {{ board.viewCount }}
                            </td>
                        </tr>
                        <tr v-for="file, idx in board.files" :key="idx">
                            <td colspan="2">
                                <a :href="file.url" download class="d-block">파일 첨부: {{ file.fileName }}</a>
                            </td>
                        </tr>
                        <tr>
                            <td class="content_box" colspan="2">
                                <div v-html="board.content"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-end mt-40 mt-lg-60">
                    <v-btn outlined large color="primary" @click="$router.go(-1)"> <span class="font-size-14 font-size-lg-18">LIST</span> </v-btn>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api"
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";

export default {
    components: {
        ClientPage,
    },
    async mounted() {
        await this.init()    
    },
    data() {
        return {
            board: undefined
        }
    },
    methods: {
        async init() {
            let { board } = await api.v1.boards.get({
                _id: this.$route.params.id 
            })
            console.log(board)
            this.board = board
        },
    },
};
</script>